@import "../../themes/colors.scss";
.dashboard-container {
  display: flex;
  flex-direction: row;
  height: "100vh";
}



.side-container {
  width: 110px;
  height: 100vh;
  z-index: 100;
  background-color: $light_blue_color;
  transition: 2s;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  overflow: hidden;
}
.side-container::-webkit-scrollbar-thumb {
  background-color: white !important;
  border-radius: 3px;
}
.side-container::-webkit-scrollbar-track {
  background: white;
}


.head-container {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  background: $white_color;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
  margin-top: 10px;
}

.table-con {
  margin-top: 10px;
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: auto;
}
.dashboard-data-container {
  margin-left: 110px;
  display: flex;
  flex-direction: column !important;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  transition: 2s;
  margin-top: 60px;
}
.MuiContainer-root.MuiContainer-maxWidthLg.css-2acaov-MuiContainer-root {
  margin-left: 8px;
}

// .side-container:hover {
//   width: 200px;
//   transition: 2s;
// }
// .side-container:hover ~ .dashboard-data-container .head-container {
//   position: sticky;
//   bottom: 0px;
//   left: 0px;
//   right: 0px;
//   margin-left: 0px;
// }
// .side-container:hover ~ .dashboard-data-container .table-con {
//   margin-top: 10px;
// }

// .side-container:hover ~ .dashboard-data-container {
//   margin-left: 200px;
//   transition: 2s;
// }

.side-container_show {
  width: 230px;
  transition: 2s;
}
// .side-container:hover ~ .dashboard-data-container .head-container {
//   position: sticky;
//   bottom: 0px;
//   left: 0px;
//   right: 0px;
//   margin-left: 0px;
// }
// .side-container:hover ~ .dashboard-data-container .table-con {
//   margin-top: 10px;
// }

.dashboard-data-container_show {
  margin-left: 230px;
  transition: 2s;
}
