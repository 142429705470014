@import "../../themes/colors.scss";

.global-configurations-profile-card {
	display: grid;
    overflow: auto;
	z-index: 100;
	background: $white_color;
	margin-left: 25px;
	// margin-top: 25px;
	padding-bottom: 20px;
	margin-right: 25px;
	padding-left: 10px;
	padding-right: 20px;
	padding-top: 20px;
	box-shadow: 0px 2px 10px $color_0000001A;
	position: relative;
    grid-auto-flow: row;
    grid-gap: 20px;
}
.password-validation {
    margin-left: 20px !important;
}
.profile-container {
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    grid-template-columns: max-content;
    grid-gap: 30px;
}
.profile-img {
    width: 350px;
    height: 350px;
    border: 0.5px solid gray;
    margin: 15px;
    object-fit: cover;
    border-radius: 10px;
}
.edit-profile-button{
    display: flex;
    width: unset;
    // float: inline-end;
    // width: 140px;
    // margin-top: -32px !important;
}
.userdata {
    /* position: relative; */
    /* right: 10rem; */
    width: 100%;
}
.change-password{
    width: 200px;
}
.profile-buttons{
    display: grid;
    grid-auto-flow: column;
    width: 100%;
    grid-template-columns: max-content;
    justify-content: center;
}
.box {
    border: 1px solid gray;
    /* width: 40rem; */
    height: 30px;
    /* display: grid; */
    /* align-items: center; */
    margin: 10px;
    padding: 5px;
    border-radius: 10px;
}
.user-data{
    display: grid;
    grid-auto-flow: column;
    // align-items: center;
    padding: 35px;
    margin: 15px;
    background-color: aliceblue;
}
.data-label{
    font-weight: 600 !important;
    position: relative;
    left: 10px;
}
.bgimg{
    display: grid;
    justify-content: center;
}
.background-img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
}
.user-details {
    // justify-self: center;
    display: grid;
    padding: 50px;
}
.company {
    font-size: 25px;
    font-weight: 600;
    margin-right: 10px;
}
.name, .role, .email {
    font-size: 20px;
}
.global-configurations-profile-styles {
	// margin-bottom: 50px;
    // margin-bottom: 50px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: max-content;
    grid-gap: 40px;
}

.profile-edit-form {
    display: grid;
    /* width: 41rem; */
}
.profile-upload {
    display: grid;
    margin-left: 20px;
}
.upload{
    // width: 100px !important;
}
.global-configurations-profile-styles >  .profile-upload > label {  // width: 350px;
    height: 55px;
}
.profile-edit-form > .global-form-text-input-field {
    width: 100%;
}

.edit-profile {
    display: grid;
    font-size: larger;
    color:$light_blue_color;
    font-weight: 600;
    margin: 30px;
    // border-bottom: 1px solid $light_blue_color;
    border-bottom: dashed;
}
.password-update-cancel {
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
}
.update-cancel {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    /* width: 100%; */
}
.password-edit-form {
    display: grid;
    /* grid-template-columns: max-content; */
    /* width: 100%; */
}