.company-project-list {
	// border: 1px solid gray;
	max-width: 100%;
	margin: 20px;
	overflow: auto;
}

// .css-1p823my-MuiListItem-root:hover {
//     // margin: 10px;
//     background-color: aliceblue;
//     border-radius: 30px;
//     border: 0.5px solid darkgrey;
// }
// .css-1p823my-MuiListItem-root {
//     margin-bottom: 10px;
//     // background-color: aliceblue;
//     border-radius: 30px;
//     border: 0.5px solid lightgray;
// }
.project {
	margin-bottom: 10px;
	// background-color: aliceblue;
	border-radius: 30px;
	border: 0.5px solid lightgray;
}

.project:hover {
	// margin: 10px;
	background-color: aliceblue;
	border-radius: 30px;
	border: 0.5px solid darkgrey;
}

.search-add {
	display: flex;
	align-items: center;
	/* flex-wrap: wrap; */
	justify-content: space-between;
}

.tb-icon-btn-add {
	position: relative;
	right: 1%;
}

.projects {
	font-size: 20px !important;
	font-weight: 500 !important;
	margin-left: 25px !important;
}

// .css-1086bdv-MuiPaper-root-MuiAccordion-root:first-of-type, .css-1086bdv-MuiPaper-root-MuiAccordion-root{
//     // border-top-left-radius: 4px;
//     // border-top-right-radius: 4px;
//     border: 1px solid gray;
//     margin-bottom: 10px;
//     border-radius: 10px !important;
// }
.company-project-parent {
	border: 1px solid gray;
	margin-bottom: 10px;
	border-radius: 10px !important;
}

.no-data-found {
	display: grid;
	justify-content: center;
	margin-top: 10rem !important;
}
.showcase-images {
    display: flex;
    flex-wrap: wrap;
}
.project-files {
    display: flex;
    flex-wrap: wrap;
}