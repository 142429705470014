@import "../../themes/colors.scss";

.navbar {
	background-color: rgb(49, 102, 186) !important;
	box-shadow: none !important;
	height: 70px;
	/* position: static; */
	// background: var(--color-surface);
	// color: var(--color-surface-text);
	box-shadow: 0 0 10px rgb(0 0 0 / 15%);
	height: 50px;
	position: sticky;
	top: 0;
	min-width: 500px;
	// z-index: 5;
	display: grid;
	grid-template-columns: 1fr max-content max-content max-content max-content max-content max-content;
	justify-content: space-between;
	// transition: background var(--transition-duration), color var(--transition-duration);
}

.logo {
	height: inherit;
}

// a {
//   text-decoration: none;
//   color: inherit;
// }
img.aauti-logo {
	width: 210px;
	height: 40px;
	/* padding: calc(var(--gap)* 0.8) var(--gap); */
	// transition: all 10px;
	padding: 5px;

}

.tool-bar {
	justify-content: space-between;
}

// .css-hip9hq-MuiPaper-root-MuiAppBar-root {
//   // margin-bottom: 2px;
// }

// .css-m5x0z-MuiTypography-root {
//   position: relative;
//   right: 10rem;
// }
// .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
//     position: relative;
//     left: 12rem;
// }

@media (min-width: 1536px) {
	.nav-container {
		max-width: unset !important;
	}
}

@media (min-width: 600px) {
	.nav-container {
		padding-left: 10px !important;
		padding-right: 24px;
	}
}

// .css-ib9iq6 {
//   margin-top: 30px !important;
// }

.user-info {
	text-transform: uppercase;
	box-shadow: none;
	display: grid;
	padding: 5px;
	position: relative;
}

.user-name {
	margin: auto;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 16ch;
}

a.name {
	text-decoration: none;
	font-size: 105%;
	color: $white_color;
}

span.user-role {
	justify-self: center;
	font-size: 75%;
	color: $lightgray_color;
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 0 6px;
	border-radius: var(--border-radius);
	transition: all var(--transition-duration);
	letter-spacing: 1px;
}

.user-profile-toggle {
	padding: 0px 15px;
	display: grid;
	grid-template-columns: max-content max-content;
	height: 100%;
	align-items: center;
	position: relative;
}
.profile-image {
	width: 40px;
	height: 40px;
	border-radius: 30px;
	/* margin-right: -10px; */
	object-fit: cover;
}