@import '../../themes/colors.scss';

.forgot-password-sub-custom-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  width: 45vw;
  padding:20px;
  position: relative;
}

.forgot-password-submit-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.forgot-password-text {
  font-size: 20px !important;
  font-weight: bold !important;
}

.forgot-password-description {
  margin-top: 30px !important;
  color: $c6c6c6_color;
  margin-bottom:30px !important;
}
.forgot-password-submit-button {
  margin-top: 50px !important;
  background-color: $color_0046fe !important;
}
.forgot-password-error-styling {
  font-size: 14px !important;
  margin-bottom: 20px !important;
  margin-right:52px !important;
  margin-top: 5px !important;
  color: $color_FF3333;
}