@import "../../themes/colors.scss";

.custom-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('../../assets/images/AV_BG_1.jpg');
  background-position: center;
  background-size: cover;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@media (max-width: 992px) {
  .custom-card-container {
    flex-direction: column;
    height: auto;
  }
}

.sub-custom-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75vh;
  width: 45vw;
  background-color: $white_color;
  box-shadow: 0px 2px 10px $color_0000001A;
  position: relative;

  @media (max-width: 1200px) {
    height: 75vh;
    width: 45vw;
  }
  @media (max-width: 992px) {
    width: 90vw;
    height: 55vh;
  }
  @media (max-width: 768px) {
    height: 60vh;
    width: 80vw;
  }

  @media (max-width: 576px) {
    height: 62vh;
    width: 91vw;
  }
}
.custom-image-card {
  height: 75vh;
  width: 45vw;
  background-color: $white_color;
  box-shadow: 0px 2px 10px $color_0000001A;

  @media (max-width: 1200px) {
    width: 45vw;
    height:75vh;
  }

  @media (max-width: 992px) {
    width: 90vw;
    height: 75vh;
  }

  @media (max-width: 768px) {
    height: 65vh;
    width: 80vw;
    margin-bottom: 30px;
  }

  @media (max-width: 576px) {
    height: 45vh;
    width: 91vw;
  }
}
.card-styling {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75vh;
  width: 25vw;
  padding: 20px;
  min-height: 35vh;

@media (max-width: 1200px) {
  width: 30vw;
  height:70vh;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (max-width: 992px) {
  width: 45vw;
  height: 50vh;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (max-width: 768px) {
  height: 50vh;
  width: 45vw;
}
@media (max-width: 576px) {
  height: 80vh;
  width: 70vw;
}
}
.common-spacing {
  margin-top: 15px;
}
.typography-text {
  margin-bottom: 5px;
  font-weight: bold !important;
  color: $black_color;
  margin-bottom: 40px !important;
}
.welcome-text {
  margin-bottom: 5px;
  color: $c6c6c6_color;
  margin-top: 15px;
}
.custom-input-label {
  color: $c8c8c8_color;
}

.remember-forgot-password-container {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center;
  margin-top: 15px;
  margin-left: -10px;
  cursor: pointer;
}

.login-MuiBox-root {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-right: 0px;
  .login-MuiCheckbox-root {
    margin-right: 0px;
  }
  #Remember-me {
    font-size: 14px;
  }
}
.forgot-password {
  font-size: 12px !important;
  margin-bottom: 5px !important;
  font-weight: bold !important;
  color: $black_color;
}
@keyframes jumpAnimation {
  0% {
    transform: translateY(0) scale(1.5);
  }
  50% {
    transform: translateY(-50px) scale(1.5);
  }
  100% {
    transform: translateY(0) scale(1.5);
  }
}
.indicator-icon-button-props {
  color: $white_color !important ;
  bottom: 10px;
  right: 20px;
}

.active-indicator-icon-button-props {
  color: $color_f35ff3 !important;
  font-weight: bold;
  animation: jumpAnimation 2s;
}
.indicator-container-props {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: right !important;
  width: 100%;
  z-index: 9999;
}
.nav-button-wrapper-props {
  z-index: 9999;
}
.image-styles {
  height: 75vh;
  width: 45vw;
  @media (max-width: 1200px) {
    height: 75vh;
    width: 45vw;
  }

  @media (max-width: 992px) {
    width: 90vw;
    height: 75vh;
  }

  @media (max-width: 768px) {
    height: 65vh;
    width: 80vw;
  }

  @media (max-width: 576px) {
    height: 45vh;
    width: 91vw;
  }
}

.about-us-styles {
  color: $white_color;
  margin-right: 10px !important;
  font-size: 12px !important;
}
.show-case-text-styles {
  color: $white_color;
  font-size: 12px !important;
}
.text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}
.login-signup-container {
  display: flex;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 15px;

}
.login-button {
  background-color: $color_0046fe !important;
  color: $white_color;
  transition: "background-color 0.1s";
  width: 90px !important;
  height: 35px;
  font-size: 10px;
  margin-right: 5px !important;
  text-align: "center";
}
.sign-up-button {
  color: $color_0046fe !important;
  border: 2px solid $color_0046fe !important;
  transition: "background-color 0.1s";
  width: 90px !important;
  height: 35px;
  font-size: 10px;
  margin-right: "5px" !important;
  text-align: "center";
  padding: 0px !important;
}

.textfield-custom-styles {
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $color_0046fe !important;
    color: $color_0046fe !important;
  }
  .MuiOutlinedInput-root {
    .MuiInputBase-input {
      color: $black_color;
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: $color_0046fe !important;
    }
  }
}
.error-text-styling {
  font-size: 12px !important;
  color: $color_FF3333;
  margin-top: 5px !important;
}

.logo-styles{
  height: 30px;
  width: 30px;
  top: 20px;
  left: 20px;
  position: absolute;
  
}