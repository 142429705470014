.not-authorized-screen {
    display: grid;
    grid-auto-flow: column;
    padding: 10rem;
    position: fixed;
    gap: 20px;
}
.error{
    border: 1px solid lightblue;
    padding: 10rem;
    justify-self: center;
    background-color: aliceblue;
    font-size: 45px;
    font-weight: 600;
    font-style: italic;
}
.not-authorized {
    border: 1px solid lightblue;
    padding: 10rem;
    justify-self: center;
    background-color: aliceblue;
    font-size: 40px;
    font-weight: 600;
    font-style: italic;
}