@import "../../themes/colors.scss";

.sd-btn-label-txt {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  text-transform: capitalize;
  font-weight: bold;
}

// .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered.css-pwcg7p-MuiCollapse-root {
//   margin-left: 30px !important;
// }
//
// .side-container:hover .sd-btn-label-txt {
//   opacity: 1;
//   transition: opacity 2s ease-in;
// }
.sd-btn-label-txt_show {
  opacity: 1;
  transition: opacity 2s ease-in;
}

.sd-logo-txt-container {
  display: flex !important;
  margin-top: 20px;
}

.sd-aauti-txt {
  color: $white_color;
  text-align: center;
  font-size: 25px !important;
}

.sd-verse-text {
  color: $white_color;
  margin-left: 10px !important;
  font-size: 25px !important;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
//
// .side-container:hover .sd-verse-text {
//   opacity: 1;
//   transition: opacity 2s ease-in;
// }
.sd-verse-text_show {
  opacity: 1;
  transition: opacity 2s ease-in;
}

.sd-select-option {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
  text-transform: capitalize;
  padding-left: 15px !important;
}
//
// .side-container:hover .sd-select-option {
//   padding-left: 10px !important;
// }
.sd-select-option_show {
  padding-left: 10px !important;
}

.MuiContainer-root.MuiContainer-maxWidthLg.sd-select-option.css-elv5p9-MuiContainer-root {
  width: auto;
}

.top-curve {
  position: absolute;
  top: -20px;
  right: 7px;
  width: 20px;
  height: 20px;
  background: $white_color;
}
.top-curve::before {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 1px;
  width: 20px;
  height: 20px;
  background: $light_blue_color;
  border-bottom-right-radius: 30px;
}
.bottom-curve {
  position: absolute;
  bottom: -20px;
  right: 7px;
  width: 20px;
  height: 20px;
  background: $white_color;
  z-index: 100;
}
.bottom-curve::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 1px;
  width: 20px;
  height: 20px;
  background: $light_blue_color;
  border-top-right-radius: 25px;
}

// .sb-aauti-verse {
//   position: relative;
//   animation: rotateAnime 5s linear 0s infinite;
//   // background-color: $white_color;
//   border: 2px solid $white_color;
//   // border-radius: 50px;
//   height: 60px !important;
//   width: 60px !important;
//   z-index: -100;
// }

// @keyframes rotateAnime {
//   0% {
//     transform: rotate(0deg);
//   }
//   25% {
//     transform: rotate(90deg);
//   }
//   50% {
//     transform: rotate(180deg);
//   }
//   75% {
//     transform: rotate(270deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

.option {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.css-wth0qt {
  display: inherit;
  margin-right: 20px;
  margin-left: -4px;
}

.css-1il9d8n {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-weight: 500;
  text-transform: capitalize;
  text-align: left;
  width: 120px;
}

.css-cveggr-MuiListItemIcon-root {
  color: $white_color !important;
  
}
// .css-10hburv-MuiTypography-root {
//   // color: $white_color !important;
//   // font-weight: 500 !important;
  
// }
.side-contains {
  margin-top: 30px !important;
}
