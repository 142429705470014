@import '../../themes/colors.scss';

.reset-password-sub-custom-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  width: 43vw;
  padding: 20px;
  position: relative;
}

.reset-password-text {
  font-size: 20px !important;
  font-weight: bold !important;
}

.reset-password-description {
  color: $c6c6c6_color;
  margin-top: 30px !important;
}

.reset-password-textfield-custom-styles {
  margin-top:30px !important;
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color:$color_0046fe!important;
    color: $color_0046fe !important; 
  }
  .MuiOutlinedInput-root {
    .MuiInputBase-input {
      color:$black_color;
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: $color_0046fe !important;
    }
  }}

  .reset-password-submit-button {
     margin-top: 50px !important;
     background-color: $color_0046fe !important;
  }
  
  .reset-password-error-styling {
    font-size: 14px !important;
    margin-bottom: 20px !important;
    margin-top: 5px !important;
    color: $color_FF3333;
  }