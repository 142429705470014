$primary_color: #a2b9bc;
$secondary_color: #aaa;
$white_color: #ffffff;
$black_color: #000000;
$blue_color: blue;
$light_blue_color: rgba(49, 102, 186, 1);
$green_color: green;
$gray_color: gray;
$lightgray_color: lightgray;
$red_color: red;
$color_0000001A: #0000001a;
$c6c6c6_color: #c6c6c6;
$c8c8c8_color: #c8c8c8;
$color_0046fe: #0046fe;
$color_FF3333: #ff3333;
$color_f35ff3: #f35ff3;
