@import '../../themes/colors.scss';

.dashboard-main-card-container {
  height: 100vh;
  padding:5px !important;
  overflow: scroll;
}
.total-user-main-container {
  display: flex;
  flex-wrap: wrap;
}
.main-title {
 text-align: center;
 font-weight: 600 !important;
 font-size: 17px !important;
 position: relative;
 bottom: 20px;
}
.total-admin-custom-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 180px;
  width: 38vw;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 32px;
  box-shadow: 0px 2px 10px $color_0000001A;
  @media (max-width: 1550px) {
    width: 45vw;
  }
  @media (max-width: 1280px) {
    width: 65vw;
  }
  @media (max-width: 1200px) {
    width: 100vw;
  }
  @media (max-width: 992px) {
    width: 100vw;
  }
  @media (max-width: 768px) {
    width: 65vw;
  }
  @media (max-width: 576px) {
    width: 65vw;
    height:100px;
  }
}
.total-user-custom-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 180px;
  width: 30vw;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 32px;
  box-shadow: 0px 2px 10px $color_0000001A;
  @media (max-width: 1280px) {
    width: 45vw;
  }
  @media (max-width: 1200px) {
    width: 100vw;
  }
  @media (max-width: 992px) {
    width: 100vw;
  }
  @media (max-width: 768px) {
    width: 65vw;
  }
  @media (max-width: 576px) {
    width: 65vw;
    height:100px;
  }
}
.icon-styling{
  margin-left: 20px;
  padding-right: 10px;
  margin-right: 70px;
  @media (max-width: 576px) {
    margin-right: 15px;
  }
}
.count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 15vh;
  // margin-left: 60px;
}
.total-user-name{
  text-align: center;
  margin-top: 25px !important;
  font-weight: bold !important;
  color: $light_blue_color;
  text-decoration: underline;
}
.total-user-name:hover{
  text-decoration: underline;
  cursor: pointer;
}
.all-details-main-container {
  display: flex;
  flex-wrap: wrap;
}
.all-details-custom-card {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 140px;
  width: 26vw;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 32px;
  box-shadow: 0px 2px 10px $color_0000001A;
  position: relative;
  min-height: 17vh;
  @media (max-width: 1280px) {
    width: 25vw;
  }
  @media (max-width: 1200px) {
    width: 100vw;
  }
  @media (max-width: 992px) {
    width: 100vw;
  }
  @media (max-width: 768px) {
    width: 65vw;
  }
  @media (max-width: 576px) {
    width: 65vw;
  }
}
.revenue-title{
  font-weight: bold !important;
  width: 7vw;
  height: 15px;
  margin-bottom: 10px !important;
  @media (max-width: 1200px) {
    width: 18vw; 
  }
  @media (max-width: 768px) {
    width: 13vw; 
  }
}
.data-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px !important;

}
.title{
  width: 5vw;
  height: 15px;
  @media (max-width: 1200px) {
    width: 18vw; 
  }
  @media (max-width: 768px) {
    width: 13vw; 
  }

}
.title-container {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 15px !important;
  margin-top: 5px !important;
}
.user-details-main-container {
  display: flex;
  flex-wrap: wrap;
}
.user-details-custom-card {
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 40vw;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 32px;
  box-shadow: 0px 2px 10px $color_0000001A;
  position: relative;
  min-height: 28vh;
  @media (max-width: 1280px) {
    width: 38.5vw;
    height: 250px;
  }
  @media (max-width: 1200px) {
    width: 150vw;
    height: 280px;
  }
  @media (max-width: 992px) {
    width: 75vw;
    height:280px;
  }
  @media (max-width: 768px) {
    width:65vw;
    height:280px;  
  }
  @media (max-width: 576px) {
    width:55vw;
    height: 280px;
  }
}
.mainTitle{
  margin-top: 10px !important;
  margin-left: 15px !important;
  margin-bottom: 0px;
  font-weight: 600 !important;
}
.description-styling{
  margin-left: 15px !important;
  margin-bottom: 10px !important;
  color:#adb8bd;
}
.table-cell-width {
  width:12vw
}
.mobile-cell-width {
  width:8vw
}
.name-cell-width {
  width:7vw
}
.scrollable-container {
  height: 23vh !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.table-container {
  border-radius: 0 !important;
  height: 21vh !important;
  box-shadow: none !important;
}
.revenue-titles {
  display: flex;
  flex-direction: row;
}
.table-main-titles{
    height: 55px !important;
    border-radius: 0 !important;
    overflow: hidden;
    box-shadow: none !important;
}
.custom-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-family: Arial, sans-serif;
}
.tooltip-price {
  font-size: 14px;
  color: #333;
  margin: 0;
  font-weight: bold;
}
.barChart-parent-container {
  display: flex;
  flex-direction: row;
  position: relative; 
  overflow: hidden; 
  margin: 20px !important;
  @media (max-width: 1500px) { 
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.dropdown-parent-container{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 0;
  margin-top: 15px;
  margin-right: 15px;
}

.sub-widgets{
  display: flex;
  gap: 8rem;
}
// .sub {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   height: 70px;
//   width: 7vw;
//   margin-top: 20px;
//   margin-bottom: 20px;
//   margin-left: -50px;
//   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1019607843);
// }