.nf_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 80vh;
  flex-wrap: wrap;
  //   border: 1px solid red;
}

.nf_text_container {
  width: 45%;
  padding: 30px;
  margin-top: 20px;
}
.nf_title {
  font-size: 26px;
  font-weight: bold;
}
.nf_image_container {
  width: 49%;
  display: flex;
  justify-content: center;
}
.nf_image {
  width: 500px;
  height: 500px;
}
.nf_para {
  font-size: 17px;
}
.nf_para2 {
  margin-top: -15px;
}
