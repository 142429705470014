@import "../../themes/colors.scss";

.otp-sub-custom-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  width: 45vw;
  padding: 20px;
  position: relative;
}

.otp-input-styles {
  color: black;
  margin-right: 8px !important;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid $color_0046fe !important;
  outline-color:  $color_0046fe;
  caret-color: $color_0046fe;
}

.otp-input-styles[type='number']::-webkit-inner-spin-button,
.otp-input-styles[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.otp-text {
  font-size: 20px !important;
  font-weight: bold !important;
  margin-bottom: 20px !important; 
}

.email-description-styles {
  color: $c6c6c6_color;
  margin-top: 20px !important; 
  text-align: center;
}

.otp-error-text-styling {
  font-size: 12px !important;
  color: $color_FF3333;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.otp-text-styling {
  font-size: 14px !important;
  margin-bottom: 20px !important;
  margin-right:55px !important;
  margin-top: 5px !important;
  color: $color_FF3333;
}

.otp-timer-text{
  font-weight: bold;
  font-size: 14px !important;
}

.email-text-styling {
  color :$color_0046fe;
  margin-top: 15px !important;
  cursor: pointer;
}

.resend-otp-styling{
  color: $color_FF3333;
  margin-top: 20px !important;
  cursor: pointer;
}