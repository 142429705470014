// background-color: #1976d2;

// button:hover {
// 	background-color: #1976d2;
// }

.agent-name {
    display: flex;
    font-size: 25px;
    font-weight: 600;
    padding: 20px;
}

// .css-vj1n65-MuiGrid-root {
//     margin: 20px;
// }