@import "./colors.scss";

.ck-content .image img , .image-inline img  {
	max-width: 600px !important; // Set the maximum width for images
	width: 600px !important; // Ensure the default width is 600px
	height: 400px !important; // Ensure the default height is 400px
	object-fit: cover; // Adjust this to 'contain' if you want to keep the aspect ratio
	// margin: 10px !important;
  }
  .ck.ck-editor__editable > figure > img, .ck.ck-editor__editable > span > p > img {
    text-align: center !important;
}

.ck.ck-balloon-panel.ck-powered-by-balloon .ck.ck-powered-by {
	display: none;
}
.error-text {
	color: red;
	text-align: end;
}
.global-form {
	margin-top: 10px;
	flex-grow: 1;
	padding-left: 10px;
	padding-right: 10px;
}
.global-form-container {
	// width: 100%;
	// height: 95%;
	position: relative;
	// position: absolute;
	width: -webkit-fill-available;
	position: absolute;
	height: calc(100% - 180px) !important;
}
.global-form-fields-container {
	border-left: 1px solid $lightgray_color;
	border-right: 1px solid $lightgray_color;
	overflow: auto;
	position: absolute;
	top: 50px;
	bottom: 50px;
	right: 0px;
	left: 0px;
}
.global-form-text-input-field {
	margin: 10px !important;
	width: 300px;
}
.global-form-action-btns {
	border: 1px solid $lightgray_color;
	// height: 50px;
	position: absolute;
	bottom: 0px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 20px;
}
.global-form-action-button {
	margin-left: 10px !important;
	// width: 100px;
	border: 1px solid $blue_color !important;
	color: $blue_color !important;
}
.global-form-action-button:hover {
	background-color: $blue_color !important;
	color: $white_color !important;
}
.global-form-tab-header {
	border: 1px solid $lightgray_color;
}

.ring {
	fill: none;
	stroke-dasharray: 720 180;
	stroke-linecap: round;
	stroke: lightblue;
	stroke-width: 25px;
	animation: progress 3s linear normal infinite;
}
.left {
	transform: translate(100px, 0) rotate(90deg);
}
.right {
	transform: translate(100px, 200px) rotate(-90deg);
	animation-delay: 1.5s;
	animation-direction: reverse;
}

@keyframes progress {
	0%,
	10% {
		stroke-dashoffset: 100;
	}
	90%,
	100% {
		stroke-dashoffset: -900;
	}
}

.global-configurations-custom-card {
	z-index: 100;
	background: $white_color;
	margin-left: 25px;
	margin-top: 25px;
	padding-bottom: 20px;
	margin-right: 25px;
	padding-left: 10px;
	padding-right: 20px;
	padding-top: 20px;
	box-shadow: 0px 2px 10px $color_0000001A;
	position: relative;
}

.global-configurations-title {
	padding-left: 10px !important;
	padding-bottom: 10px;
	color: #505458;
}
.global-configurations-description {
	padding-left: 10px;
	padding-bottom: 30px;
	color: #c5ccd1;
}
.global-configurations-container-styles {
	margin-bottom: 50px;
}

.global-form-text {
	margin: 10px !important;
	font-weight: bold !important;
}
// .side-option {
// 	text-decoration: none;
// 	font-size: 1rem;
// 	color: $white_color;
// 	margin-left: 5px;
// 	padding: 5px 7px;
// 	font-weight: 500;
// 	font-family: Roboto, Helvetica, Arial, sans-serif;
// 	display: inline-flex;
// 	align-items: center;
// 	justify-content: center;
// 	position: relative;
// 	box-sizing: border-box;
// 	height: 55px;
// }
.side-option.active {
	background-color: $white_color;
	color: $light_blue_color;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	outline: 0px;
	border: 0px;
	// margin: 0px;
	cursor: pointer;
	user-select: none;
	vertical-align: middle;
	appearance: none;
	text-decoration: none;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	font-weight: 500;
	// font-size: 0.9375rem;
	line-height: 1.75;
	letter-spacing: 0.02857em;
	height: 55px;
	// min-width: 64px;
	// padding: 8px 11px;
	// border-radius: 4px;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
