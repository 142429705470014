@import "../../themes/colors.scss";
.tb-operation-header {
	height: 60px;
	margin-bottom: 5px;
	padding-top: 15px;
	padding-bottom: 10px;
}
.tb-head-search-text-field {
}
.tb-head-text-field {
	width: 300px;
}
.tab-head-act-icons {
	width: 200px;
	height: 45px;
	display: flex;
	justify-content: flex-end;
}
.grid-table {
	// height: 90%;
	height: calc(100% - 110px);
	min-height: 300px;
	position: relative;
}
@media screen and (max-width: 977px) {
	.tb-head-text-field {
		width: 200px;
	}
}
@media screen and (max-width: 737px) {
	.tb-operation-header {
		height: 120px;
	}
	.tab-head-act-icons {
		margin-top: 15px;
		justify-content: flex-start;
	}
	.grid-table {
		// height: 83%;
		height: calc(100% - 170px);
	}
}

@media screen and (max-width: 537px) {
	.tb-operation-header {
		height: 180px;
	}
	.tb-head-search-text-field {
		margin-top: 15px !important;
	}
	.grid-table {
		// height: 77%;
		height: calc(100% - 230px);
	}
}

.tb-page-number-container {
	height: 40px;
	width: 100px;
	position: absolute;
	z-index: 100;
	bottom: 0px;
	left: 10px;
}

.tb-icon-btn {
	margin-right: 8px !important;
	border: 1px solid $blue_color !important;
}
.tb-icon-btn:hover {
	background-color: $blue_color !important;
}
.tb-icon-btn .tb-act-icons {
	color: $blue_color !important;
}
.tb-icon-btn:hover .tb-act-icons {
	color: $white_color !important;
}

.tb-icon-btn-view {
	margin-right: 8px !important;
	border: 1px solid $gray_color !important;
}
.tb-icon-btn-view:hover {
	background-color: $gray_color !important;
}
.tb-icon-btn-view .tb-act-icons-view {
	color: $gray_color !important;
}
.tb-icon-btn-view:hover .tb-act-icons-view {
	color: $white_color !important;
}

.tb-icon-btn-add {
	border: 1px solid $green_color !important;
}
.tb-icon-btn-add:hover {
	background-color: $green_color !important;
}
.tb-icon-btn-add .tb-act-icons-add {
	color: $green_color !important;
}
.tb-icon-btn-add:hover .tb-act-icons-add {
	color: $white_color !important;
}

.tb-icon-btn-del {
	margin-right: 8px !important;
	border: 1px solid $red_color !important;
}
.tb-icon-btn-del:hover {
	background-color: $red_color !important;
}
.tb-icon-btn-del .tb-act-icons-del {
	color: $red_color !important;
}
.tb-icon-btn-del:hover .tb-act-icons-del {
	color: $white_color !important;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value.ag-cell-focus {
	border: none;
}

.ag-theme-alpine {
	//   --ag-foreground-color: rgb(126, 46, 132);
	//   --ag-background-color: rgb(249, 245, 227);
	//   --ag-header-foreground-color: rgb(204, 245, 172);
	//   --ag-header-background-color: rgb(209, 64, 129);
	//   --ag-header-background-color: rgb(245, 208, 105);
	//   --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
	// --ag-odd-row-background-color: rgb(249, 245, 227);
	//   --ag-header-column-resize-handle-color: rgb(126, 46, 132);

	--ag-font-size: 14px;
}
// .ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value {
// 	width: unset !important;
// }
