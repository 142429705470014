.license-container {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 60px;
    font-weight: 600;
    margin: 10px;
}

.license-data {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 5px;
    font-size: 20px;
}

.license-request {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 5px;
}
.license-request > .global-form-text-input-field {
    display: grid;
    width: 100%;
    height: 150px;
}

button.send-req-btn {
    width: max-content;
}